import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { PageTitle } from '../components/styles/global';

const PrivacyPolicy = () => {
  const { t } = useTranslation('translation');

  return (
    <>
      <SEO title={t('privacyPolicy')} />
      <PageTitle>{t('privacyPolicy')}</PageTitle>
      <p>
        <h3>Administrator i właściciel danych</h3>
        <p>
          <address>
            Professional Piercers Poland
            <br />
            <a href="mailto:piercerspoland@gmail.com" className="link">
              piercerspoland@gmail.com
            </a>
            <br />
            ul. Krańcowa 16
            <br />
            87-300 Brodnica
          </address>
        </p>
        <h3>Czas retencji</h3>
        <p>
          Dane są przechowywane przez okres niezbędny do świadczenia usługi żądanej przez
          Użytkownika, lub też przez okres określony przez cele opisane w niniejszym dokumencie.
          Użytkownik może zawsze poprosić Administratora Danych o zawieszenie lub usunięcie danych.
        </p>
        <h3>Zbierane typy danych</h3>
        <p>
          Wśród typów danych osobowych, które zbiera ta aplikacja, samodzielnie lub za pośrednictwem
          osób trzecich, są: Cookie, Dane o wykorzystaniu Inne zgromadzone Dane Osobowe mogą być
          opisane w innych sekcjach niniejszej polityki prywatności lub kontekstowo, za pomocą
          dedykowanego tekstu wyświetlanego przy gromadzeniu danych. Dane osobowe mogą być swobodnie
          dostarczane przez Użytkownika albo automatycznie zbierane podczas korzystania z tej
          Aplikacji. Jakiekolwiek korzystanie z plików plików cookie - lub innych narzędzi
          monitorowania - przez tę Aplikację lub przez właścicieli usług stron trzecich
          wykorzystywanych przez tę Aplikację, o ile nie zaznaczono inaczej, służy do identyfikacji
          Użytkowników i zapamiętywania ich preferencji, wyłącznie w celu świadczenia usług
          wymaganych przez Użytkownika. Nie udostępnienie niektórych danych osobowych może Aplikacji
          świadczenie usług. Użytkownik ponosi odpowiedzialność za Dane Osobowe stron trzecich
          publikowane lub udostępniane przez tę Aplikację i deklaruje prawo do komunikowania się z
          nimi lub nadawania do nich, zwalniając Administratora Danych z wszelkiej
          odpowiedzialności.
        </p>
        <h3>Miejsce</h3>
        <p>
          Dane są przetwarzane w biurach Administratora Danych i w innych miejscach, w których
          znajdują się związane z przetwarzaniem strony. Aby uzyskać więcej informacji, należy
          skontaktować się z Administratorem Danych.
        </p>
        <h3>Metody przetwarzania</h3>
        <p>
          Administrator Danych przetwarza Dane Użytkowników w sposób prawidłowy i podejmuje
          odpowiednie środki bezpieczeństwa w celu ochrony przed nieuprawnionym dostępem,
          ujawnieniem, modyfikacją lub nieuprawnionym zniszczeniem danych. Przetwarzanie danych
          odbywa się za pomocą komputerów i/lub narzędzi IT, zgodnie z procedurami organizacyjnymi i
          sposobami ściśle związanymi ze wskazanymi celami. Poza Administratorem Danych, w
          niektórych przypadkach, dane mogą być dostępne dla określonych typów osób odpowiedzialnych
          za działania serwisu (administrację, sprzedaż, marketing, system prawny, administrację
          systemu) lub podmiotów zewnętrznych (takich jak dostawcy usług technicznych, listonosze,
          dostawcy hostingu, firmy informatyczne, agencje komunikacyjne) mianowanych, w razie
          potrzeby, Procesorami Danych przez Właściciela. Aktualny wykaz tych stron można uzyskać od
          Administratora Danych w każdej chwili.
        </p>
        <h3>Wykorzystanie zgromadzonych danych</h3>
        <p>
          Dane dotyczące Użytkownika są zbierane w celu umożliwienia Aplikacji świadczenia usług,
          jak również do następujących celów: Interakcje z zewnętrznymi sieciami i platformami
          społecznościowymi. Dane Osobowe wykorzystywane do każdego celu są opisane w poszczególnych
          sekcjach niniejszego dokumentu.
        </p>
        <h3>Dane dodatkowe na temat zbierania i przetwarzania danych</h3>
        <h3>Działania prawne</h3>
        <p>
          Dane Osobowe Użytkownika mogą być wykorzystywane do celów prawnych przez Administratora
          Danych, w sądzie lub w działaniach prowadzących do możliwych działań prawnych wynikających
          z niewłaściwego stosowania tej Aplikacji lub związanych z nią usług. Użytkownik powinien
          być świadomy faktu, że Administrator Danych może być zobowiązany do ujawnienia Danych
          Osobowych na żądanie władz.
        </p>
        <h3>Dodatkowe informacje o Danych Osobowych Użytkownika</h3>
        <p>
          Oprócz informacji zawartych w niniejszej polityce prywatności, ta Aplikacja może zapewnić
          Użytkownikowi dodatkowe i kontekstowe informacje dotyczących określonych usług lub
          gromadzenia i przetwarzania Danych Osobowych na żądanie.
        </p>
        <h3>Logi Systemowe i Konserwacja</h3>
        <p>
          Do celów eksploatacji i konserwacji, ta aplikacja i wszelkie usługi przez stron trzecich
          mogą gromadzić pliki stanowiące zapis interakcji z tą Aplikacją (logi systemowe) lub użyć
          do tego celu innych Danych Osobowe (takich jak adres IP).
        </p>
        <h3>Informacje nie zawarte w niniejszej polityce</h3>
        <p>
          Więcej szczegółów dotyczących gromadzenia lub przetwarzania Danych Osobowych można uzyskać
          od Administratora Danych w dowolnym momencie. Informacje kontaktowe znaleźć można na
          początku niniejszego dokumentu.
        </p>
        <h3>Prawa użytkowników</h3>
        <p>
          Użytkownicy mają prawo w dowolnym momencie dowiedzieć się, czy ich Dane Osobowe były
          przechowywane i skonsultować się z Administratorem Danych, aby dowiedzieć się o ich
          zawartości i pochodzeniu w celu sprawdzenia ich dokładności lub żądania ich uzupełnienia,
          anulowania, aktualizacji lub poprawy, lub do ich przekształcenia do postaci anonimowej lub
          do zablokowania wszelkich danych przechowywanych z naruszeniem prawa, a także do
          sprzeciwienia się ich wykorzystaniu z wszelkich uzasadnionych powodów. Wnioski należy
          przesyłać do Administratora Danych, korzystając z informacji kontaktowych podanych
          powyżej. Ta Aplikacja nie obsługuje żądań "Do Not Track". Aby określić, czy którekolwiek z
          usług stron trzecich obsługuje żądania "Do Not Track", należy przeczytać ich polityki
          prywatności.
        </p>
        <h3>Zmiany niniejszej polityki prywatności</h3>
        <p>
          Administrator Danych zastrzega sobie prawo do wprowadzania zmian w niniejszej polityce
          prywatności w dowolnym momencie przez powiadomienie swoich Użytkowników na tej stronie.
          Zaleca się, aby sprawdzać tę stronę często, zwracając uwagę na datę ostatniej modyfikacji
          wymienioną na dole. Jeśli Użytkownik sprzeciwia się dowolnej ze zmian w polityce,
          Użytkownik musi zaprzestać korzystania z tej Aplikacji i może zażądać, aby Administrator
          Danych usunął Dane Osobowe. Jeśli nie zaznaczono inaczej, aktualna w danym momencie
          polityka prywatności ma zastosowanie do wszystkich Danych Osobowych Użytkowników, jakie
          posiada Administrator Danych.
        </p>
        <h3>Informacje na temat niniejszej polityki prywatności</h3>
        <p>Administrator danych jest odpowiedzialny za niniejszą politykę prywatności.</p>
        <p>Data ostatniej modyfikacji: 01.01.2020 r.</p>
      </p>
    </>
  );
};

export default PrivacyPolicy;
